<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>{{ getCommonText('budget') }}</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                :header="header"
                :isParentGuExist="isParentGuExist"
                :isHeadGu="isHeadGu"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
                @downloadBatchReportsPaid="downloadBatchReportsPaid"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
            </div>
            <div class="right-content">
                <c-budget-forms-data-transfer
                    v-if="!isGkkpMode && !(progress < 100) && variantAttribute"
                    :header="header"
                    :lng="lng"
                    :getDataTransferText="getDataTransferText"
                    :getErrText="getErrText"
                    :makeToast="makeToast"
                    @reloadTable="loadDatas"
                />
                <div class="filter-actions"
                    v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)">
                    <c-budget-forms-copy-data 
                        :budgetForm="budgetForm"
                        :header="header"
                        :isLoad="isLoad"
                        :total="total"
                        @keyPress="keyPress"
                    />
                </div>
                <forms-save-btn
                    :progress="progress"
                    :isLoad="isLoad"
                    :variantAttribute="variantAttribute"
                    @prepareForSave="prepareForSave"
                ></forms-save-btn>
            </div>
        </div>

        <breadcrumbs-filter 
            :header="{...header, formName: this.formName, spfName: this.spfName}" 
            :data-type-filter="dataTypeFilter" 
            :variant-name="variantName" 
            @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>
        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >
                <template #top-row="data">
                    <td class="td-numbering"></td>
                    <td class="td-numbering table-success">1</td>
                    <td class="td-numbering table-danger">2</td>
                    <td class="td-numbering table-info">3</td>
                    <td class="td-numbering table-primary">4</td>
                    <td class="td-numbering table-warning">5</td>
                    <td class="td-numbering table-success">6</td>
                    <td class="td-numbering table-danger">7</td>
                    <td class="td-numbering table-primary">8</td>
                    <td class="td-numbering table-info">9</td>
                    <td class="td-numbering table-warning">10</td>
                    <td class="td-numbering table-primary">11</td>
                    <td class="td-numbering table-success">12</td>
                    <td class="td-numbering"></td>
                </template>
                <template #cell(name)="data">
                    <div
                        class="column-width-100 bp-category-name">
                        {{ data.item['name_' + lng] }} 
                    </div>
                </template>
                <template #cell(base_salary)="data">
                    <div class="text-right">
                        {{ $n(data.item.base_salary) }}
                    </div>
                </template>
                <template #cell(coefficient_salary)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.coefficient_salary"
                                  @change="v => data.item.coefficient_salary = v"
                                  @blur="inputFixed(data.item, 'coefficient_salary', data.item.coefficient_salary, 2, null)" 
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')">
                    </b-form-input>
                    <div v-else class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(official_salary)="data">
                    <div class="text-right">
                        {{ $n(data.item.official_salary) }}
                    </div>
                </template>
                <template #cell(wages)="data">
                    <div class="text-right">
                        {{ $n(data.item.wages) }}
                    </div>
                </template>
                <template #cell(number_of_cases)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.number_of_cases"
                                  @change="v => data.item.number_of_cases = v"
                                  @blur="inputFixed(data.item, 'number_of_cases', data.item.number_of_cases, 0, 0)" 
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')">
                    </b-form-input>
                    <div v-else class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(number_of_jurors)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.number_of_jurors"
                                  @change="v => data.item.number_of_jurors = v"
                                  @blur="inputFixed(data.item, 'number_of_jurors', data.item.number_of_jurors, 0, 0)" 
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')">
                    </b-form-input>
                    <div v-else class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(process_duration)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.process_duration"
                                  @change="v => data.item.process_duration = v"
                                  @blur="inputFixed(data.item, 'process_duration', data.item.process_duration, 0, 0)" 
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')">
                    </b-form-input>
                    <div v-else class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(cost_amount)="data">
                    <div class="text-right">
                        {{ $n(data.item.cost_amount) }}
                    </div>
                </template>
                <template #cell(social_tax)="data">
                    <div class="text-right">
                        {{ $n(data.item.social_tax) }}
                    </div>
                </template>
                <template #cell(soc_deductions)="data">
                    <div class="text-right">
                        {{ $n(data.item.soc_deductions) }}
                    </div>
                </template>
                <template #cell(amount)="data">
                    <div class="text-right">
                        {{ $n(data.item.amount) }}
                    </div>
                </template>
                <template #cell(more)="data">
                    <div class="text-center">
                        <i title="Удалить запись" class="icon icon-clear table-remove" v-if="variantAttribute" @click="deleteItem(null, data.item, data.index)"></i>
                    </div>
                </template>
            </b-table>
        </div>
        <files-updown :header="header"
                      :variant-attribute="variantAttribute" 
                      :load="load" 
                      @getFiles="getFiles"
                      @onFilePreview="onFilePreview"
                      ref="fileUpdown"
        ></files-updown>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import FormsHandlerMixin1 from "./mixins/forms-handler-mixin-1";
import FormsSaveBtn from "./components/forms-save-btn.vue";
import FormsDownloadReprt from "./components/forms-download-reprt.vue";
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import Decimal from 'decimal.js';
import CBudgetFormsDataTransfer from './components/budget-forms-data-transfer.vue';

export default {
    name: 'Form01_134',
    components: { 
        BudgetHeader, 
        FilesUpdown, 
        BudgetFormsList, 
        BreadcrumbsFilter, 
        FormsSaveBtn, 
        FormsDownloadReprt,
        CBudgetFormsCopyData,
        CBudgetFormsDataTransfer
    },
    mixins: [FormsHandlerMixin1],
    data() {
        return {
            form: {
                code: '01-134',
                name_kk: 'Алқабилерге сыйақы төлеуді есептеу',
                name_ru: 'Расчет выплаты вознаграждений присяжным заседателям'
            },
            defaultNames: {
                ru: "Вознаграждения присяжным заседателям",
                kk: "Алқабилерге сыйақылар"
            },
            files: null,
            curYear: 0,
            bdo: null,
            sn: null,
            opv: null,
            vosms: null,
            so: null,
        };
    },

    async mounted() {
        this.curYear = new Date().getFullYear();
    },

    methods: {
        addItem() {
            const that = this;
            const item = { id: -1 };
            that.itemUpdate(item);
            this.calculatedColumns(item);
            that.budgetForm.push(item);
        },
        itemUpdate(item) {
            this.$set(item, 'name_ru', this.defaultNames.ru);
            this.$set(item, 'name_kk', this.defaultNames.kk);
            this.$set(item, 'coefficient_salary', null);
            this.$set(item, 'number_of_cases', 0);
            this.$set(item, 'number_of_jurors', 0);
            this.$set(item, 'process_duration', 0);
            this.$set(item, 'base_salary', this.bdo);
        },

        setName(lng){
            if (lng === 'kk') {
                return this.defaultNames.kk;
            }
            else {
                return this.defaultNames.ru;
            }
        },

        getFiles(data) {
            this.files = data;
        },

        setDatasParams(val) {
            const item = {
                id: val.id,
                name_ru: this.setName('ru'),
                name_kk: this.setName('kk'),
                coefficient_salary: val.coefficient_salary,
                number_of_cases: val.number_of_cases,
                number_of_jurors: val.number_of_jurors,
                process_duration: val.process_duration
            };

            item.base_salary = this.bdo;

            this.calculatedColumns(item);

            this.budgetForm.push(item);
        },

        async loadDictNormativeInds() {
            try {
                this.bdo = 0;
                this.sn = 0;
                this.opv = 0;
                this.vosms = 0;
                this.so = 0;

                const response = await fetch(`/api-py/dictionary-normative-inds/${this.header.year}/${this.header.cur_year}/${this.header.variant_date_time}`);
                const items = await response.json();

                for (const row of items) {
                    if (row.code === 'BDO') {
                        this.bdo = row.value;
                        continue;
                    }
                    if (row.code === 'SN') {
                        const sn = new Decimal(row.value)
                        this.sn = sn.div(100).toNumber();
                        continue;
                    }
                    if (row.code === 'OPV') {
                        const opv = new Decimal(row.value)
                        this.opv = opv.div(100).toNumber();
                        continue;
                    }
                    if (row.code === 'VOSMS') {
                        const vosms = new Decimal(row.value)
                        this.vosms = vosms.div(100).toNumber();
                        continue;
                    }
                    if (row.code === 'SO') {
                        const so = new Decimal(row.value)
                        this.so = so.div(100).toNumber();
                        continue;
                    }
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDictNormativeInds`, error.toString());
            }
        },

        prepareForSave() {
            const values = [];
            for (const row of this.budgetForm) {
                const item = Object.assign({}, this.header);
                this.$set(item, 'id', row.id);
                this.$set(item, 'name_ru', this.defaultNames.ru);
                this.$set(item, 'name_kk', this.defaultNames.kk);
                this.$set(item, 'coefficient_salary', row.coefficient_salary);
                this.$set(item, 'number_of_cases', parseInt(row.number_of_cases));
                this.$set(item, 'number_of_jurors', parseInt(row.number_of_jurors));
                this.$set(item, 'process_duration', parseInt(row.process_duration));
                values.push(item);
            }
            if (values.length > 0) {
                let total = 0;
                const sum = parseFloat(this.budgetForm[0].cost_amount) + parseFloat(this.budgetForm[0].soc_deductions);
                if (sum > 0) {
                    total = Math.ceil(this.budgetForm[0].amount)
                }
                this.save({values, header: {...this.header, value: total}});
            }
        },

        async loadSpecificData() {
            await this.loadDictNormativeInds();
        },
        
        prepareForDelete(row, index) {
            this.$set(row, 'coefficient_salary', 0);
            this.$set(row, 'number_of_cases', 0);
            this.$set(row, 'number_of_jurors', 0);
            this.$set(row, 'process_duration', 0);
            this.$set(this.header, 'value', 0)
        },

        calculatedColumns(item) {
            const that = this;

            Object.defineProperty(item, 'official_salary', {
                get: function () {
                    const salary = new Decimal(item.base_salary);
                    if (item.coefficient_salary !== null && item.coefficient_salary !== '' && item.coefficient_salary > 0) {
                        return salary.mul(item.coefficient_salary).div(100).toDecimalPlaces(2, Decimal.ROUND_HALF_UP).toNumber();
                    }
                    else {
                        return salary.toDecimalPlaces(2, Decimal.ROUND_HALF_UP).toNumber();
                    }
                }
            });
            Object.defineProperty(item, 'wages', {
                get: function () {
                    const salary = new Decimal(item.official_salary);
                    const fiftyPersent = 0.5;
                    return salary.mul(fiftyPersent).toDecimalPlaces(2, Decimal.ROUND_HALF_UP).toNumber();
                }
            });
            Object.defineProperty(item, 'cost_amount', {
                get: function () {
                    const workDaysNumb = 22;
                    const wages = new Decimal(item.wages);
                    return wages.div(workDaysNumb).mul(item.number_of_jurors).mul(item.process_duration).div(1000).toDecimalPlaces(2, Decimal.ROUND_HALF_UP).toNumber();
                }
            });
            Object.defineProperty(item, 'soc_deductions', {
                get: function () {
                    const wages = new Decimal(item.wages - (item.wages * that.opv));
                    return wages.mul(that.so).div(1000).toDecimalPlaces(2, Decimal.ROUND_HALF_UP).toNumber();
                }
            });
            Object.defineProperty(item, 'social_tax', {
                get: function () {
                    const wages = new Decimal(item.wages);
                    const wagesOpv = wages.mul(that.opv);
                    const wagesMinusWagesOpv = wages.minus(wagesOpv);
                    const wagesVosms = wages.mul(that.vosms);
                    const wagesOpvVosms = wagesMinusWagesOpv.minus(wagesVosms);
                    const wagesOpvVosmsSn = wagesOpvVosms.mul(that.sn);
                    const wagesOpvSo = wagesMinusWagesOpv.mul(that.so);
                    const wagesVosmsMinusWagesOpvSo = wagesOpvVosmsSn.minus(wagesOpvSo);

                    return wagesVosmsMinusWagesOpvSo.div(1000).toDecimalPlaces(2, Decimal.ROUND_HALF_UP).toNumber();
                }
            });
            Object.defineProperty(item, 'amount', {
                get: function () {
                    const amount = new Decimal(item.cost_amount).plus(item.social_tax).plus(item.soc_deductions);
                    return amount.toDecimalPlaces(2, Decimal.ROUND_HALF_UP).toNumber();
                }
            });
        }, 

        getFormText(field, extra = null) {
            const link = 'modules.budget_request.calculation_forms.table_headers.form_01_134.';
            return this.getText(link, field, extra)
        },
    },

    computed: {
        total() {
            let total = 0;
            const sum = parseFloat(this.budgetForm[0].cost_amount) + parseFloat(this.budgetForm[0].soc_deductions);
            if (sum > 0) {
                total = Math.ceil(this.budgetForm[0].amount)
            }
            return total;
        },

        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'name',
                    label: this.getCommonText('name')
                },
                {
                    key: 'base_salary',
                    label: this.getFormText('base_salary')
                },
                {
                    key: 'coefficient_salary',
                    label: this.getFormText('salary_coef')
                },
                {
                    key: 'official_salary',
                    label: this.getFormText('judge_salary')
                },
                {
                    key: 'wages',
                    label: this.getFormText('percent_salary')
                },
                {
                    key: 'number_of_cases',
                    label: this.getFormText('case_count')
                },
                {
                    key: 'number_of_jurors',
                    label: this.getFormText('jury_count')
                },
                {
                    key: 'process_duration',
                    label: this.getFormText('process_period')
                },
                {
                    key: 'cost_amount',
                    label: this.getFormText('expense_sum')
                },
                {
                    key: 'social_tax',
                    label: this.getFormText('social_tax')
                },
                {
                    key: 'soc_deductions',
                    label: this.getFormText('social_ins')
                },
                {
                    key: 'amount',
                    label: this.getFormText('total')
                },
                {
                    key: 'more',
                    label: ''
                }
                ];
            };
            return [];
        }
    }
};
</script>

<style scoped>
.bp-category-name {
    display: flex;
    justify-content: space-between;
}
</style>